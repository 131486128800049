var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._l(_vm.steps, function (step, index) {
    return [_vm.stepIndex === index && !_vm.isSuccess ? _c('CardStep', {
      key: index,
      attrs: {
        "current": index + 1,
        "total": _vm.steps.length,
        "is-prev": index !== 0,
        "is-next": true
      },
      on: {
        "prev": function prev($event) {
          _vm.stepIndex--;
        },
        "next": _vm.nextForm
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": "700",
        "mb": "42px"
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]), step.type === 'nutritionAssessment' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Berat Badan/Weight (kg) ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "min": "0",
        "placeholder": "Masukkan Berat Badan/Weight (kg)",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.weight,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "weight", $$v);
        },
        expression: "nutritionAssessment.weight"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Tinggi Badan/Height (cm) ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "min": "0",
        "placeholder": "Masukkan Tinggi Badan/Height (cm)",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.height,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "height", $$v);
        },
        expression: "nutritionAssessment.height"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" IMT/BMI (kg/m2): ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "min": "0",
        "placeholder": "Masukkan IMT/BMI (kg/m2)",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.bodyMassIndex,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "bodyMassIndex", $$v);
        },
        expression: "nutritionAssessment.bodyMassIndex"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Status Gizi ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "placeholder": "Masukkan Status Gizi",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.nutritionalStatus,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "nutritionalStatus", $$v);
        },
        expression: "nutritionAssessment.nutritionalStatus"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Kebutuhan Energi Bassal (kkal) ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "min": "0",
        "placeholder": "Masukkan Kebutuhan Energi Bassal (kkal)",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.basalEnergyNeeds,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "basalEnergyNeeds", $$v);
        },
        expression: "nutritionAssessment.basalEnergyNeeds"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Kondisi Keibuan ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Kondisi Keibuan",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.maternalCondition,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "maternalCondition", $$v);
        },
        expression: "nutritionAssessment.maternalCondition"
      }
    }, [_c('option', {
      attrs: {
        "value": "Tidak ada"
      }
    }, [_vm._v(" Tidak ada ")]), _c('option', {
      attrs: {
        "value": "Hamil Trimester 1"
      }
    }, [_vm._v(" Hamil Trimester 1 ")]), _c('option', {
      attrs: {
        "value": "Hamil Trimester 2"
      }
    }, [_vm._v(" Hamil Trimester 2 ")]), _c('option', {
      attrs: {
        "value": "Hamil Trimester 3"
      }
    }, [_vm._v(" Hamil Trimester 3 ")]), _c('option', {
      attrs: {
        "value": "Menyusui Bayi 0-6 Bulan"
      }
    }, [_vm._v(" Menyusui Bayi 0-6 Bulan ")]), _c('option', {
      attrs: {
        "value": "Menyusui Bayi 7-12 Bulan"
      }
    }, [_vm._v(" Menyusui Bayi 7-12 Bulan ")])])], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Tambahan kalori kondisi keibuan ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "min": "0",
        "placeholder": "Masukkan Tambahan kalori kondisi keibuan",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.maternalCalorieAddition,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "maternalCalorieAddition", $$v);
        },
        expression: "nutritionAssessment.maternalCalorieAddition"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Pertahankan Hal Ini / What to Maintain ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "placeholder": "Masukkan Pertahankan Hal Ini / What to Maintain",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.maintain,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "maintain", $$v);
        },
        expression: "nutritionAssessment.maintain"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Perbaiki Hal InI / What to Improve ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "placeholder": "Masukkan Perbaiki Hal InI / What to Improve",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionAssessment.improve,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionAssessment, "improve", $$v);
        },
        expression: "nutritionAssessment.improve"
      }
    })], 1)], 1)], 1) : _vm._e(), step.type === 'physicalActivityHistory' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Jenis olahraga & durasi/Exercise type & duration ")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.physicalActivityHistory.excercise,
        expression: "physicalActivityHistory.excercise"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset'
          }
        },
        expression: "{\n              border: '1px solid #C4C4C4',\n              padding: '18px',\n              width: '100%',\n              borderRadius: '6px',\n              height: '180px',\n              '&:focus, &:focus-visible': {\n                outline: 'unset',\n              },\n            }"
      }],
      attrs: {
        "rows": "8",
        "placeholder": "Masukkan Jenis olahraga & durasi/Exercise type & duration"
      },
      domProps: {
        "value": _vm.physicalActivityHistory.excercise
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(_vm.physicalActivityHistory, "excercise", $event.target.value);
        }
      }
    })], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Status Kecukupan Aktivitas Fisik ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Masukan Status Kecukupan Aktivitas Fisik",
        "height": "62px"
      },
      model: {
        value: _vm.physicalActivityHistory.activityStatus,
        callback: function callback($$v) {
          _vm.$set(_vm.physicalActivityHistory, "activityStatus", $$v);
        },
        expression: "physicalActivityHistory.activityStatus"
      }
    }, [_c('option', {
      attrs: {
        "value": "Kurang Aktif"
      }
    }, [_vm._v(" Kurang Aktif ")]), _c('option', {
      attrs: {
        "value": "Aktif Level Rendah"
      }
    }, [_vm._v(" Aktif Level Rendah ")]), _c('option', {
      attrs: {
        "value": "Aktif Level Sedang"
      }
    }, [_vm._v(" Aktif Level Sedang ")]), _c('option', {
      attrs: {
        "value": "Aktif Level Tinggi"
      }
    }, [_vm._v(" Aktif Level Tinggi ")])])], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Presentase Aktivitas Fisik ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Masukan Presentase Aktivitas Fisik",
        "height": "62px"
      },
      model: {
        value: _vm.physicalActivityHistory.activityPercentage,
        callback: function callback($$v) {
          _vm.$set(_vm.physicalActivityHistory, "activityPercentage", $$v);
        },
        expression: "physicalActivityHistory.activityPercentage"
      }
    }, [_c('option', {
      attrs: {
        "value": "0"
      }
    }, [_vm._v(" 0% ")]), _c('option', {
      attrs: {
        "value": "10"
      }
    }, [_vm._v(" 10% ")]), _c('option', {
      attrs: {
        "value": "30"
      }
    }, [_vm._v(" 30% ")]), _c('option', {
      attrs: {
        "value": "40"
      }
    }, [_vm._v(" 40% ")]), _c('option', {
      attrs: {
        "value": "50"
      }
    }, [_vm._v(" 50% ")]), _c('option', {
      attrs: {
        "value": "60"
      }
    }, [_vm._v(" 60% ")]), _c('option', {
      attrs: {
        "value": "70"
      }
    }, [_vm._v(" 70% ")]), _c('option', {
      attrs: {
        "value": "80"
      }
    }, [_vm._v(" 80% ")]), _c('option', {
      attrs: {
        "value": "90"
      }
    }, [_vm._v(" 90% ")])])], 1)], 1)], 1) : _vm._e(), step.type === 'calorieMacroNutrientIntake' ? _c('c-box', [_vm.calorieMacroNutrientIntake ? _c('EnergiForm', {
      attrs: {
        "without-adds": "",
        "aktivitas-fisik": _vm._f("parseFloat")(_vm.profileGizi.physicalActivity),
        "defisit-kalori": _vm._f("parseFloat")(_vm.profileGizi.calorieDeficit),
        "kebutuhan-energi": _vm._f("parseFloat")(_vm.profileGizi.basalEnergyNeeds),
        "tambahan-kalori": _vm._f("parseFloat")(_vm.profileGizi.extraMaternalCalories)
      },
      model: {
        value: _vm.calorieMacroNutrientIntake,
        callback: function callback($$v) {
          _vm.calorieMacroNutrientIntake = $$v;
        },
        expression: "calorieMacroNutrientIntake"
      }
    }) : _vm._e(), _vm.calorieMacroNutrientIntake ? _c('ProteinForm', {
      attrs: {
        "without-adds": "",
        "kebutuhan-asupan-energi": _vm._f("parseFloat")(_vm.calorieMacroNutrientIntake.energyIntakeNeeds),
        "weight": _vm._f("parseFloat")(_vm.profileGizi.weight)
      },
      model: {
        value: _vm.calorieMacroNutrientIntake,
        callback: function callback($$v) {
          _vm.calorieMacroNutrientIntake = $$v;
        },
        expression: "calorieMacroNutrientIntake"
      }
    }) : _vm._e(), _vm.calorieMacroNutrientIntake ? _c('LemakForm', {
      attrs: {
        "value": _vm.calorieMacroNutrientIntake,
        "without-adds": "",
        "kebutuhan-asupan-energi": _vm._f("parseFloat")(_vm.calorieMacroNutrientIntake.energyNeeds)
      }
    }) : _vm._e(), _vm.calorieMacroNutrientIntake ? _c('KarbohidratForm', {
      attrs: {
        "value": _vm.calorieMacroNutrientIntake,
        "without-adds": "",
        "kebutuhan-asupan-energi": _vm._f("parseFloat")(_vm.calorieMacroNutrientIntake.energyNeeds),
        "kebutuhan-lemak": _vm._f("parseFloat")(_vm.calorieMacroNutrientIntake.fatNeedsConstant),
        "kebutuhan-protein": _vm._f("parseFloat")(_vm.calorieMacroNutrientIntake.proteinNeedsConstant)
      }
    }) : _vm._e(), _vm.calorieMacroNutrientIntake ? _c('SeratForm', {
      attrs: {
        "value": _vm.calorieMacroNutrientIntake,
        "without-adds": ""
      }
    }) : _vm._e()], 1) : _vm._e(), step.type === 'otherNote' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Catatan Lainnya dari Hasil Follow Up ")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.otherNote,
        expression: "otherNote"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset'
          }
        },
        expression: "{\n              border: '1px solid #C4C4C4',\n              padding: '18px',\n              width: '100%',\n              borderRadius: '6px',\n              height: '180px',\n              '&:focus, &:focus-visible': {\n                outline: 'unset',\n              },\n            }"
      }],
      attrs: {
        "rows": "8",
        "placeholder": "Masukkan Catatan Lainnya dari Hasil Follow Up"
      },
      domProps: {
        "value": _vm.otherNote
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }
          _vm.otherNote = $event.target.value;
        }
      }
    })], 1)], 1) : _vm._e(), step.type === 'primaryNutritionDiagnosis' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Problem ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Masukan Kebutuhan Lemak / Fat (gram)",
        "height": "62px"
      },
      model: {
        value: _vm.primaryNutritionDiagnosis.problem,
        callback: function callback($$v) {
          _vm.$set(_vm.primaryNutritionDiagnosis, "problem", $$v);
        },
        expression: "primaryNutritionDiagnosis.problem"
      }
    }, [_c('option', {
      attrs: {
        "value": "Masih Sama Dengan Sebelumnya"
      }
    }, [_vm._v(" Masih Sama Dengan Sebelumnya ")]), _c('option', {
      attrs: {
        "value": "Buat Data Baru"
      }
    }, [_vm._v(" Buat Data Baru ")])])], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Etiology ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Masukan Kebutuhan Lemak / Fat (gram)",
        "height": "62px"
      },
      model: {
        value: _vm.primaryNutritionDiagnosis.etiology,
        callback: function callback($$v) {
          _vm.$set(_vm.primaryNutritionDiagnosis, "etiology", $$v);
        },
        expression: "primaryNutritionDiagnosis.etiology"
      }
    }, [_c('option', {
      attrs: {
        "value": "Masih Sama Dengan Sebelumnya"
      }
    }, [_vm._v(" Masih Sama Dengan Sebelumnya ")]), _c('option', {
      attrs: {
        "value": "Buat Data Baru"
      }
    }, [_vm._v(" Buat Data Baru ")])])], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Sign & Symptoms ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Masukan Kebutuhan Lemak / Fat (gram)",
        "height": "62px"
      },
      model: {
        value: _vm.primaryNutritionDiagnosis.signSymptom,
        callback: function callback($$v) {
          _vm.$set(_vm.primaryNutritionDiagnosis, "signSymptom", $$v);
        },
        expression: "primaryNutritionDiagnosis.signSymptom"
      }
    }, [_c('option', {
      attrs: {
        "value": "Masih Sama Dengan Sebelumnya"
      }
    }, [_vm._v(" Masih Sama Dengan Sebelumnya ")]), _c('option', {
      attrs: {
        "value": "Buat Data Baru"
      }
    }, [_vm._v(" Buat Data Baru ")])])], 1)], 1)], 1) : _vm._e(), step.type === 'nutritionIntervention' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Preskripsi Diet/Nutrition Prescription ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Masukan Preskripsi Diet/Nutrition Prescription",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionIntervention.nutritionPrescription,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionIntervention, "nutritionPrescription", $$v);
        },
        expression: "nutritionIntervention.nutritionPrescription"
      }
    }, [_c('option', {
      attrs: {
        "value": "Masih Sama Dengan Sebelumnya"
      }
    }, [_vm._v(" Masih Sama Dengan Sebelumnya ")]), _c('option', {
      attrs: {
        "value": "Buat Data Baru"
      }
    }, [_vm._v(" Buat Data Baru ")])])], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Target Pencapaian/Goals (SMART) ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-select', {
      attrs: {
        "placeholder": "Masukan Target Pencapaian/Goals (SMART)",
        "height": "62px"
      },
      model: {
        value: _vm.nutritionIntervention.goal,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionIntervention, "goal", $$v);
        },
        expression: "nutritionIntervention.goal"
      }
    }, [_c('option', {
      attrs: {
        "value": "Masih Sama Dengan Sebelumnya"
      }
    }, [_vm._v(" Masih Sama Dengan Sebelumnya ")]), _c('option', {
      attrs: {
        "value": "Buat Data Baru"
      }
    }, [_vm._v(" Buat Data Baru ")])])], 1)], 1)], 1) : _vm._e(), step.type === 'nutritionMonitoring' ? _c('c-box', [_c('c-checkbox-group', {
      attrs: {
        "variant-color": "green",
        "default-value": _vm.nutritionMonitoring.checkParts
      },
      model: {
        value: _vm.nutritionMonitoring.checkParts,
        callback: function callback($$v) {
          _vm.$set(_vm.nutritionMonitoring, "checkParts", $$v);
        },
        expression: "nutritionMonitoring.checkParts"
      }
    }, _vm._l(_vm.checkParts, function (item, index_) {
      return _c('c-checkbox', {
        key: index_,
        attrs: {
          "w": "100%",
          "mb": "16px",
          "font-size": "18px",
          "line-height": "27px",
          "variant-color": "primary",
          "value": item
        }
      }, [_vm._v(" " + _vm._s(item) + " ")]);
    }), 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-weigh": "400",
        "font-family": "Roboto",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px",
        "color": "primary.400"
      }
    }, [_vm._v(" Tuliskan indikator spesifik yang akan dimonitor & kriterianya ")])], 1), _c('table', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "w": "100%"
      }
    }, [_c('thead', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "bg": "rgba(0, 140, 129, 0.1);",
        "color": "primary.400",
        "text-align": "left",
        "text-transform": "uppercase",
        "font-weight": "700",
        "font-size": "12px"
      }
    }, [_c('tr', [_c('th', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "px": "20px",
        "py": "7px",
        "border-top-left-radius": "12px",
        "border-bottom": "1px solid #008C81"
      }
    }, [_vm._v(" Jenis Indikator ")]), _c('th', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "px": "20px",
        "py": "7px",
        "border": "1px solid #008C81",
        "border-top": "0"
      }
    }, [_vm._v(" Kriteria Indikator ")]), _c('th', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "px": "20px",
        "py": "7px",
        "border-top-right-radius": "12px",
        "border-bottom": "1px solid #008C81"
      }
    })])]), _c('tbody', _vm._l(_vm.nutritionMonitoring.indicators, function (item, index_) {
      return _c('tr', {
        key: 'indicators' + index_
      }, [_c('td', {
        directives: [{
          name: "chakra",
          rawName: "v-chakra"
        }],
        attrs: {
          "border": "1px solid #C4C4C4"
        }
      }, [_c('c-input', {
        attrs: {
          "type": "text",
          "border": "unset",
          "placeholder": "Masukkan Jenis Indikator",
          "height": "62px"
        },
        model: {
          value: item.type,
          callback: function callback($$v) {
            _vm.$set(item, "type", $$v);
          },
          expression: "item.type"
        }
      })], 1), _c('td', {
        directives: [{
          name: "chakra",
          rawName: "v-chakra"
        }],
        attrs: {
          "border": "1px solid #C4C4C4"
        }
      }, [_c('c-input', {
        attrs: {
          "type": "text",
          "border": "unset",
          "placeholder": "Masukkan Kriteria Indikator",
          "height": "62px"
        },
        model: {
          value: item.criteria,
          callback: function callback($$v) {
            _vm.$set(item, "criteria", $$v);
          },
          expression: "item.criteria"
        }
      })], 1), _c('td', {
        directives: [{
          name: "chakra",
          rawName: "v-chakra"
        }],
        attrs: {
          "border": "1px solid #C4C4C4",
          "align": "center"
        }
      }, [_c('c-button', {
        attrs: {
          "min-width": "auto",
          "variant-color": "red",
          "variant": "link"
        },
        on: {
          "click": function click($event) {
            return _vm.nutritionMonitoring.indicators.splice(index_, 1);
          }
        }
      }, [_c('c-image', {
        attrs: {
          "src": require('@/assets/icon-trash-line.svg'),
          "alt": "empty"
        }
      })], 1)], 1)]);
    }), 0)]), _c('c-button', {
      attrs: {
        "variant-color": "primary",
        "variant": "outline",
        "border-radius": "0 0 12px 12px",
        "h": "62px",
        "w": "100%",
        "left-icon": "add",
        "mb": "25px"
      },
      on: {
        "click": _vm.addNutritionMonitoringIndicator
      }
    }, [_vm._v(" Tambah Indikator ")])], 1) : _vm._e()], 1) : _vm._e()];
  }), _vm.isSuccess ? _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "align": "center"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h2",
      "font-size": "36px",
      "font-weight": "600",
      "color": "black.900",
      "margin-bottom": "54px",
      "text-align": "center",
      "line-height": "54px"
    }
  }, [_vm._v(" Submit Berhasil! ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/paid.png'),
      "mx": "auto",
      "margin-bottom": "30px"
    }
  }), _c('c-text', {
    attrs: {
      "margin-bottom": "20px",
      "color": "gray.900",
      "font-size": ['14px', '24px'],
      "line-height": ['21px', '36px'],
      "font-family": "Roboto"
    },
    domProps: {
      "textContent": _vm._s('Follow Up Notes kamu berhasil di-submit')
    }
  }), _c('c-button', {
    attrs: {
      "as": "router-link",
      "to": {
        name: 'nutri.follow-up-notes'
      },
      "font-weight": "700",
      "right-icon": "arrow-forward",
      "color": "primary.400",
      "variant": "outline",
      "border-color": "primary.400",
      "border-radius": "200px",
      "py": "10px",
      "px": "30px"
    }
  }, [_vm._v(" Pilih Ahli Gizi ")])], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }